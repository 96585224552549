import { AppointmentDragging, Resource, View } from 'devextreme-react/scheduler';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Button from 'devextreme/ui/button';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import moment from 'moment';
import { ReactQueryKey } from '@enums';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import { updateTask } from '@components/Calendar/redux/actions';
import { InfiniteData, useQueryClient } from 'react-query';
import { hasEntityAccessNew } from '@utils/roles';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { find } from 'lodash/fp';
import { selectWorkspaceId } from '@state/selectors';
import { useRemindersMutations } from '@hooks/useReminders';
import { addHours, getTimeDiff, parseUtcDate } from '@utils/dates';
import { orderBy } from 'lodash';
import { useDrawerOpenState } from '@components/LayoutNew/MainMenu/useDrawerOpenState';
import { useClientFilterState } from '@components/Scheduler/useClientFilterState';
import { Popover } from '@material-ui/core';
import { CellClickEvent, ViewType } from 'devextreme/ui/scheduler';
import { PaginatedTasks } from '@components/Scheduler/useTasks';
import { QueryParamsEnum, useQueryParam } from '@hooks/useQueryParam';
import querystring from 'querystring';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { PrivilegedTask, TaskStatus } from '@generated/types/graphql';
import { useWorkOrderStatuses } from '@hooks/workOrders/useWorkOrderStatuses';
import { MAIN_MENU_COLLAPSED_WIDTH, MAIN_MENU_WIDTH } from '@components/LayoutNew/MainMenu/constants';
import { TRAY_COLLAPSED_WIDTH, TRAY_WIDTH } from '@components/Scheduler/styled';
import { useToast } from '@hooks/useToast';
import { useDraggedEvent } from '@components/Scheduler/Dispatcher/Resources/useDraggedEvent';
import { useSelectedDate } from '@components/Scheduler/Dispatcher/useSelectedDate';
import { ReminderView } from '@features/Work/ReminderView';
import { useModal } from '@common/PromiseModal';
import { WeekEvent } from './WeekEvent';
import { EventListTooltip } from '../../components/EventListTooltip';
import { Container, OverflowLabel, SchedulerWrapper } from './styled';
import { SCHEDULER_DRAGGING_GROUP_ID } from '../../consts';
import { ResourceCell } from './Resource/Resource';
import { useDispatherTasks, useFilterState } from '../useTasks';
import { ACTIVITY_PANEL_COLLAPSED_WIDTH, ACTIVITY_PANEL_WIDTH } from '../styled';
import { useLimitWarning } from './useLimitWarning';
import { eventTracking } from '../../../../services/eventTracking';

interface Props {
  onTaskClick: (task: any) => void;
  isTrayCollapsed: boolean;
  isActivityCollapsed: boolean;
}

const groups = ['assigneeResId'];

const MAX_VISIBLE_SWIMLANES = 20;

export const Resources = ({ onTaskClick, isTrayCollapsed, isActivityCollapsed }: Props) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const companyId = useAppSelector(selectWorkspaceId);

  const scrolledToCurrentRef = useRef(false);

  const { data: statuses = [] } = useWorkOrderStatuses();
  const [isDrawerOpen] = useDrawerOpenState();

  const {
    updateMutation: { mutate: updateReminder }
  } = useRemindersMutations(companyId);

  const schedulerRef = useRef();

  const { showError } = useToast();
  const { openModal } = useModal();

  const { data: assignees } = useAllCompaniesUsers();

  const [currentDate, setCurrentDate] = useSelectedDate();
  const [currentView = 'week'] = useQueryParam(QueryParamsEnum.CalendarViewType);

  const [, setFilterState] = useFilterState();
  const [clientFilters] = useClientFilterState();
  const location = useLocation();

  const handleViewChange = useCallback(
    async (view: string) => {
      const params = querystring.parse(location.search?.substring(1));
      params[QueryParamsEnum.CalendarViewType] = view;

      navigate(`?${querystring.encode(params)}`);
    },
    [location.search]
  );

  useEffect(() => {
    setFilterState((prev) => ({
      ...prev,
      dateRangeFilter: {
        startDate:
          (currentView || '').toLowerCase() === 'week'
            ? moment(currentDate).startOf('week').toDate()
            : moment(currentDate).startOf('day').toDate(),
        endDate:
          (currentView || '').toLowerCase() === 'week'
            ? moment(currentDate).endOf('week').toDate()
            : moment(currentDate).endOf('day').toDate()
      }
    }));
  }, [currentView, currentDate, setFilterState]);

  const { tasks: events } = useDispatherTasks();

  const preparedEvents = useMemo(() => {
    const result = events.map((event) => {
      let startDateInternal;
      let endDateInternal;

      if ('dueDate' in event) {
        if (['timelineweek', 'week'].includes(currentView.toLowerCase())) {
          startDateInternal = moment(event.dueDate).startOf('date').toDate();
          endDateInternal = moment(event.dueDate).endOf('date').toDate();
        } else {
          startDateInternal = event.dueDate;
          endDateInternal = addHours(event.dueDate, 1);
        }
      } else if (['timelineweek', 'week'].includes(currentView.toLowerCase())) {
        if (event.startDate && event.endDate) {
          startDateInternal = moment(event.startDate).startOf('date').toDate();
          endDateInternal = moment(event.endDate).endOf('date').toDate();
        } else {
          const startOrEnd = event.startDate || event.endDate;
          startDateInternal = moment(startOrEnd).startOf('date').toDate();
          endDateInternal = moment(startOrEnd).endOf('date').toDate();
        }
      } else if (event.startDate && event.endDate) {
        const isTooShort = moment(event.endDate).diff(moment(event.startDate), 'm') < 30;

        startDateInternal = moment(event.startDate).toDate();
        endDateInternal = isTooShort ? addHours(event.startDate, 1) : moment(event.endDate).toDate();
      } else if (event.startDate) {
        startDateInternal = moment(event.startDate).toDate();
        endDateInternal = addHours(event.startDate, 1);
      } else {
        startDateInternal = moment(event.endDate).toDate();
        endDateInternal = addHours(event.endDate, 1);
      }

      return {
        ...event,
        text: event.title,
        startDateInternal,
        endDateInternal,
        allDay: !event.isVisit && (event.startDateAllDay || event.endDateAllDay)
      };
    });

    return orderBy(result, (event) => moment(event.endDateInternal).diff(moment(event.startDateInternal)), 'desc');
  }, [events, currentView]);

  const handleAddEventToCalendar = useCallback(
    async (e: any) => {
      const {
        id,
        project,
        isField,
        taskVisitsByTaskIdConnection,
        startDate: taskOriginalStartDate,
        endDate: taskOriginalEndDate,
        title
      } = e.fromData;
      let originalStartDate = taskOriginalStartDate;
      let originalEndDate = taskOriginalEndDate;

      if (isField) {
        const index = taskVisitsByTaskIdConnection.nodes.findIndex((visit) => !visit.isCompleted);
        if (index >= 0) {
          originalStartDate = parseUtcDate(taskVisitsByTaskIdConnection.nodes[index].startDate);
          originalEndDate = parseUtcDate(taskVisitsByTaskIdConnection.nodes[index].endDate);
        } else {
          originalStartDate = null;
          originalEndDate = null;
        }
      }
      const { assigneeResId } = e.itemData;

      let startDate = new Date(e.itemData.startDateInternal);
      let endDate: Date;

      if (currentView === 'day') {
        // dropped into specific time
        if (originalStartDate && originalEndDate) {
          endDate = addHours(startDate, getTimeDiff(originalEndDate, originalStartDate, 'h'));
        } else {
          endDate = addHours(startDate, 1);
        }
      } else if (originalStartDate && originalEndDate) {
        startDate.setHours(originalStartDate.getHours());
        startDate.setMinutes(originalStartDate.getMinutes());
        endDate = addHours(startDate, getTimeDiff(originalEndDate, originalStartDate, 'h'));
      } else if (originalEndDate) {
        endDate = new Date(e.itemData.startDateInternal);
        endDate.setHours(originalEndDate.getHours());
        endDate.setMinutes(originalEndDate.getMinutes());
        startDate = addHours(endDate, -1);
      } else if (originalStartDate) {
        startDate.setHours(originalStartDate.getHours());
        startDate.setMinutes(originalStartDate.getMinutes());
        endDate = addHours(startDate, 1);
      } else {
        startDate.setHours(12);
        startDate.setMinutes(0);
        endDate = addHours(startDate, 1);
      }

      let updatePayload = {
        id,
        projectId: project.id,
        assigneeId: assigneeResId
      } as any;

      if (isField) {
        const index = taskVisitsByTaskIdConnection.nodes.findIndex((visit) => !visit.isCompleted);

        if (index < 0) {
          // all visits completed or no visits at all, create new one
          updatePayload = {
            ...updatePayload,
            visits: [
              ...taskVisitsByTaskIdConnection.nodes,
              {
                startDate,
                endDate,
                startDateAllDay: false,
                endDateAllDay: false
              }
            ]
          };
        } else {
          // update first not completed visit
          const visits = [...taskVisitsByTaskIdConnection.nodes];

          visits[index] = {
            ...visits[index],
            startDate,
            endDate
          };

          // move other visits relative to first one
          const diff = moment(startDate).diff(originalStartDate);

          for (let i = index + 1; i < visits.length; i++) {
            visits[i] = {
              ...visits[i],
              startDate: moment(parseUtcDate(visits[i].startDate)).add(diff, 'milliseconds').toDate(),
              endDate: moment(parseUtcDate(visits[i].endDate)).add(diff, 'milliseconds').toDate()
            };
          }

          updatePayload = {
            ...updatePayload,
            visits
          };
        }
      } else {
        updatePayload = {
          ...updatePayload,
          startDate,
          endDate
        };
      }

      const result = await dispatch(updateTask(updatePayload));

      if (!result) {
        return;
      }

      queryClient.setQueriesData<InfiniteData<PaginatedTasks>>(
        [ReactQueryKey.Tasks, ReactQueryKey.DispatcherTrayTasks],
        (data) => {
          if (!data || !data.pages) {
            return data;
          }

          return {
            ...data,
            pages: data.pages.map((page) => ({
              ...page,
              totalCount: data.pages[0].totalCount - 1,
              tasks: page.tasks.filter((task) => task.id !== id)
            }))
          };
        }
      );

      const assignee = find({ id: assigneeResId }, assignees);
      queryClient.setQueriesData<InfiniteData<PaginatedTasks>>(
        [ReactQueryKey.Tasks, ReactQueryKey.TasksDispatcher],
        (data) => {
          if (!data || !data.pages) {
            return data;
          }

          const pages = data.pages.slice();

          let taskToAdd: PrivilegedTask;

          if (!updatePayload.visits) {
            taskToAdd = {
              ...e.fromData,
              startDate,
              endDate,
              taskStatus: find({ id: result.status }, statuses),
              assignee
            };
          } else {
            taskToAdd = {
              ...e.fromData,
              taskStatus: find({ id: result.status }, statuses),
              assignee,
              taskVisitsByTaskIdConnection: {
                nodes: result.visits
              }
            };
          }

          pages[0] = {
            ...pages[0],
            totalCount: pages[0].totalCount + 1,
            tasks: pages[0].tasks.concat([taskToAdd])
          };

          return {
            ...data,
            pages
          };
        }
      );

      queryClient.setQueryData([ReactQueryKey.Tasks, ReactQueryKey.TasksDetails, id], (data) => {
        if (!data) {
          return data;
        }

        if (!updatePayload.visits) {
          return {
            ...data,
            startDate,
            endDate,
            taskStatus: find({ id: result.status }, statuses),
            assignee
          };
        } else {
          return {
            ...data,
            taskStatus: find({ id: result.status }, statuses),
            assignee,
            taskVisitsByTaskIdConnection: {
              nodes: result.visits
            }
          };
        }
      });

      eventTracking.track({
        event: 'WORK_ORDER_ASSIGNED_VIA_DISPATCHER',
        description: 'Work order assigned via dispatcher',
        customFields: {
          ObjectId: id,
          ObjectName: title,
          Source: 'Platform'
        }
      });
    },
    [dispatch, queryClient, assignees, currentView, statuses]
  );

  const handleRemoveEventFromCalendar = useCallback(
    async (e: any) => {
      const { id, project, dueDate, isVisit, isCollaborator, status } = e.itemData;

      if (isVisit || isCollaborator || dueDate) {
        e.cancel = true;

        return;
      }

      if ([TaskStatus.OnTheWay, TaskStatus.ClockedIn].includes(status)) {
        showError('The assignee cannot be changed while the Work Order is in “On the Way“ or “Clocked In“ status.');
        e.cancel = true;

        return;
      }

      const result = await dispatch(
        updateTask({
          id,
          projectId: project.id,
          assigneeId: null
        })
      );

      queryClient.invalidateQueries<InfiniteData<PaginatedTasks>>([
        ReactQueryKey.Tasks,
        ReactQueryKey.DispatcherTrayTasks
      ]);

      queryClient.setQueryData([ReactQueryKey.Tasks, ReactQueryKey.TasksDetails, id], (data) => {
        if (!data) {
          return data;
        }

        return {
          ...data,
          assignee: null,
          taskStatus: find({ id: result.status }, statuses)
        };
      });

      queryClient.setQueriesData<InfiniteData<PaginatedTasks>>(
        [ReactQueryKey.Tasks, ReactQueryKey.TasksDispatcher],
        (data) => {
          if (!data || !data.pages) {
            return data;
          }

          const pages = data.pages.slice();
          pages[0] = {
            ...pages[0],
            totalCount: pages[0].totalCount - 1,
            tasks: pages[0].tasks.filter((task) => task.id !== id)
          };

          return {
            ...data,
            pages
          };
        }
      );
    },
    [dispatch, queryClient, statuses, showError]
  );

  const handleUpdatingEvent = useCallback(
    (e: any) => {
      const { oldData, newData } = e;
      if (oldData.isCollaborator) {
        e.cancel = true;
      }

      if (
        oldData.assignee?.id !== newData.assigneeResId &&
        [TaskStatus.OnTheWay, TaskStatus.ClockedIn].includes(oldData?.taskStatus?.id)
      ) {
        showError('The assignee cannot be changed while the Work Order is in “On the Way“ or “Clocked In“ status.');
        e.cancel = true;
      }
    },
    [showError]
  );

  const handleUpdateEvent = useCallback(
    async (e: any) => {
      const event = e.appointmentData;

      const {
        id,
        project,
        visitId,
        taskVisitsByTaskIdConnection,
        startDateInternal,
        endDateInternal,
        assigneeResId,
        startDate: originalStartDate,
        endDate: originalEndDate
      } = event;

      if ('dueDate' in event) {
        updateReminder({
          id,
          dto: { dueDate: startDateInternal },
          companyId
        });

        return;
      }

      const payload = { id, projectId: project.id, assigneeId: assigneeResId } as any;

      if (visitId) {
        const visitIndexToUpdate = taskVisitsByTaskIdConnection.nodes.findIndex((visit) => visit.id === visitId);
        const updatedVisits = [...taskVisitsByTaskIdConnection.nodes];
        const startDate = new Date(startDateInternal);
        const endDate = new Date(endDateInternal);

        if (currentView !== 'day') {
          startDate.setHours(originalStartDate.getHours());
          startDate.setMinutes(originalStartDate.getMinutes());
          endDate.setHours(originalEndDate.getHours());
          endDate.setMinutes(originalEndDate.getMinutes());
        }
        updatedVisits[visitIndexToUpdate] = {
          ...updatedVisits[visitIndexToUpdate],
          startDate,
          endDate
        };

        payload.visits = updatedVisits;
      } else {
        payload.startDate = startDateInternal;
        payload.endDate = endDateInternal;
      }

      const result = await dispatch(updateTask(payload));

      if (!result) {
        return;
      }

      const assignee = find({ id: assigneeResId }, assignees);

      queryClient.setQueriesData<InfiniteData<PaginatedTasks>>(
        [ReactQueryKey.Tasks, ReactQueryKey.TasksDispatcher],
        (data) => {
          if (!data || !data.pages) {
            return data;
          }

          const pages = data.pages.slice();

          let taskUpdatePayload: PrivilegedTask;

          if (payload.visits) {
            taskUpdatePayload = {
              taskVisitsByTaskIdConnection: {
                nodes: result.visits
              }
            };
          } else {
            taskUpdatePayload = {
              startDate: payload.startDate ?? null,
              endDate: payload.endDate ?? null
            };
          }

          taskUpdatePayload.assignee = assignee;

          pages[0] = {
            ...pages[0],
            tasks: pages[0].tasks.map((task) =>
              task.id === id
                ? {
                    ...task,
                    ...taskUpdatePayload
                  }
                : task
            )
          };

          return {
            ...data,
            pages
          };
        }
      );

      queryClient.setQueryData([ReactQueryKey.Tasks, ReactQueryKey.TasksDetails, id], (data) => {
        if (!data) {
          return data;
        }

        let taskUpdatePayload: PrivilegedTask;

        if (payload.visits) {
          taskUpdatePayload = {
            taskVisitsByTaskIdConnection: {
              nodes: result.visits
            }
          };
        } else {
          taskUpdatePayload = {
            startDate: payload.startDate ?? null,
            endDate: payload.endDate ?? null
          };
        }

        taskUpdatePayload.assignee = assignee;

        return {
          ...data,
          ...taskUpdatePayload
        };
      });
    },
    [dispatch, queryClient, assignees, currentView, companyId, updateReminder]
  );

  const handleAppointmentClick = useCallback(
    (e: any) => {
      const { id, project /* isReminder, project */ } = e.appointmentData;

      const task = find({ id }, preparedEvents);

      // prevent event toolip showing up
      e.cancel = true;

      if (!task || !hasEntityAccessNew(task, 'view')) {
        return;
      }

      if ('dueDate' in e.appointmentData) {
        if (!project) {
          showError(`You don't have access to the project`);

          return;
        }

        openModal<void>(({ onClose }) => <ReminderView initialValues={e.appointmentData} onClose={onClose} />, {
          isHeaderShown: false
        });

        return;
      }
      /*
    // for bulk checkbox only
    if (e.event.target.type === 'checkbox') {
      return;
    }

    e.cancel = true;
    const { id, isReminder, project } = e.appointmentData;
    if (isReminder) {
      navigate(getRecordMainPagePath(project));

      return;
    }
    */

      // TODO check what exactly is modified
      // take unmodified by devextreme-scheduler task object

      onTaskClick(task);
    },
    [onTaskClick, preparedEvents, showError, openModal]
  );

  const renderTimeCell = useCallback((itemData: any) => {
    return <div>{itemData.text.replace(':00', '')}</div>;
  }, []);

  const renderEventOverflowCollector = useCallback((props) => {
    const { appointmentCount } = props;

    return <OverflowLabel>...{appointmentCount} more</OverflowLabel>;
  }, []);

  const handleAppointmentFormOpening = useCallback((e: any) => {
    e.cancel = true;
  }, []);

  const handleContentReady = useCallback(
    (e: any) => {
      const todayButton = document.getElementById('schedulerTodayButton');

      if (!todayButton) {
        const element = document.querySelectorAll('.dx-scheduler-navigator .dx-collection');
        const container = document.createElement('div');
        container.id = 'schedulerTodayButton';
        container.classList.add('today-button');

        element[0].prepend(container);

        // eslint-disable-next-line no-new
        new Button(container, {
          text: 'Today',
          onClick: () => {
            schedulerRef.current.instance.option('currentDate', new Date());
          }
        });
      }

      if (currentView === 'day' && !scrolledToCurrentRef.current) {
        e?.component?.scrollTo(new Date());
        scrolledToCurrentRef.current = true;
      }

      if (currentView !== 'day') {
        scrolledToCurrentRef.current = false;
      }
    },
    [currentView]
  );

  const assigneesRes = useMemo(
    () =>
      assignees
        .filter((assignee) =>
          (clientFilters.assignees || []).filter((a) => a !== -1).length === 0
            ? true
            : clientFilters.assignees.includes(assignee.id)
        )
        .map((assignee) => ({
          ...assignee,
          id: assignee.id,
          text: `${assignee.firstName} ${assignee.lastName}`,
          color: 'transparent'
        })),
    [assignees, clientFilters.assignees]
  );

  const slicedAssignees = useMemo(() => assigneesRes.slice(0, MAX_VISIBLE_SWIMLANES), [assigneesRes]);

  useLimitWarning(assigneesRes.length, MAX_VISIBLE_SWIMLANES);

  const timeRange = currentView === 'day' ? { startDayHour: 0, endDayHour: 24 } : {};

  useEffect(() => {
    if (isDrawerOpen || !isTrayCollapsed || !isActivityCollapsed) {
      // eslint-disable-next-line no-underscore-dangle
      schedulerRef.current?._instance?.repaint();
    }
  }, [isDrawerOpen, isTrayCollapsed, isActivityCollapsed]);

  const width = useMemo(() => {
    const menuWidth = isDrawerOpen ? MAIN_MENU_WIDTH : MAIN_MENU_COLLAPSED_WIDTH;
    const trayWidth = isTrayCollapsed ? TRAY_COLLAPSED_WIDTH : TRAY_WIDTH;
    const activityWidth = isActivityCollapsed ? ACTIVITY_PANEL_COLLAPSED_WIDTH : ACTIVITY_PANEL_WIDTH;

    return `calc(100vw - ${menuWidth} - 16px - 16px - ${trayWidth} - ${activityWidth})`;
  }, [isDrawerOpen, isTrayCollapsed, isActivityCollapsed]);

  const [dayPopover, setDayPopover] = React.useState<{
    element: HTMLElement;
    startDate: Date;
    endDate: Date;
    assigneeId: number;
  } | null>(null);

  const handleDayPopoverClose = () => {
    setDayPopover(null);
  };

  const handleCalendarCellClick = useCallback(({ cellData, cellElement }: CellClickEvent) => {
    setDayPopover({
      element: cellElement,
      startDate: cellData.startDate,
      endDate: cellData.endDate,
      assigneeId: cellData.groups.assigneeResId
    });
  }, []);

  const eventsForRange = useMemo(() => {
    if (!dayPopover) {
      return [];
    }

    const rangeStart = moment(dayPopover.startDate);
    const rangeEnd = moment(dayPopover.endDate);

    return preparedEvents.filter((event) => {
      if (event.assigneeResId !== dayPopover.assigneeId) {
        return false;
      }

      const startDate = 'dueDate' in event ? undefined : event.startDate;
      const endDate = 'dueDate' in event ? event.dueDate : event.endDate;

      if (startDate && !endDate) {
        const date = moment(startDate);

        if (date.isBetween(rangeStart, rangeEnd, 'minutes', '[)')) {
          return true;
        }
      }

      if (endDate && !startDate) {
        const date = moment(endDate);

        if (date.isBetween(rangeStart, rangeEnd, 'seconds', '[)')) {
          return true;
        }
      }

      if (startDate && endDate) {
        return (
          rangeStart.isBetween(startDate, endDate, 'seconds', '[)') ||
          rangeEnd.isBetween(startDate, endDate, 'seconds', '(]') ||
          moment(endDate).isBetween(rangeStart, rangeEnd, 'seconds', '(]') ||
          moment(startDate).isBetween(rangeStart, rangeEnd, 'minutes', '[)')
        );
      }

      return false;
    });
  }, [preparedEvents, dayPopover]);

  const handlePopoverEventClick = useCallback(
    (task: any) => {
      if (task && hasEntityAccessNew(task, 'view')) {
        if ('dueDate' in task) {
          if (!task.project) {
            showError(`You don't have access to the project`);

            return;
          }

          openModal<void>(({ onClose }) => <ReminderView initialValues={task} onClose={onClose} />, {
            isHeaderShown: false
          });

          return;
        }
        onTaskClick(task);
        setDayPopover(null);
      }
    },
    [onTaskClick, showError, openModal]
  );

  const [, setDraggedEvent] = useDraggedEvent();

  const handleEventDrag = useCallback(
    ({ itemData }: { itemData: PrivilegedTask }) => {
      setDraggedEvent(itemData);
    },
    [setDraggedEvent]
  );

  const handleEventDragEnd = useCallback(() => {
    setDraggedEvent(null);
  }, [setDraggedEvent]);

  const eventRender = useCallback((data) => <WeekEvent data={data} view={currentView as ViewType} />, [currentView]);

  return (
    <Container width={width}>
      <SchedulerWrapper
        ref={schedulerRef}
        height="calc(100vh - 48px - 91px - 40px)"
        width={width}
        defaultCurrentView="week"
        currentView={currentView as ViewType}
        onCurrentDateChange={setCurrentDate}
        onCurrentViewChange={handleViewChange}
        dataSource={preparedEvents}
        editing
        onAppointmentUpdating={handleUpdatingEvent}
        onAppointmentUpdated={handleUpdateEvent}
        onAppointmentClick={handleAppointmentClick}
        timeCellRender={renderTimeCell}
        maxAppointmentsPerCell={['timelineweek', 'week'].includes(currentView.toLowerCase()) ? 5 : 1}
        appointmentCollectorRender={renderEventOverflowCollector}
        onAppointmentFormOpening={handleAppointmentFormOpening}
        appointmentTooltipComponent={EventListTooltip}
        focusStateEnabled={false}
        onContentReady={handleContentReady}
        groups={groups}
        resourceCellComponent={ResourceCell}
        {...timeRange}
        startDateExpr="startDateInternal"
        endDateExpr="endDateInternal"
        onCellClick={handleCalendarCellClick}
      >
        <Resource fieldExpr="assigneeResId" dataSource={slicedAssignees} />
        <View type={'timelineWeek' as ViewType} name="week" cellDuration={60 * 24} appointmentRender={eventRender} />
        <View type={'timelineDay' as ViewType} name="day" cellDuration={60} appointmentRender={eventRender} />
        <AppointmentDragging
          group={SCHEDULER_DRAGGING_GROUP_ID}
          onRemove={handleRemoveEventFromCalendar}
          onAdd={handleAddEventToCalendar}
          onDragMove={handleEventDrag}
          onDragEnd={handleEventDragEnd}
        />
      </SchedulerWrapper>
      <Popover
        open={Boolean(dayPopover) && eventsForRange.length > 0}
        anchorEl={dayPopover?.element}
        onClose={handleDayPopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <div>
          {eventsForRange.map((event) => (
            <EventListTooltip onClick={handlePopoverEventClick} key={event.id} data={{ appointmentData: event }} />
          ))}
        </div>
      </Popover>
    </Container>
  );
};
