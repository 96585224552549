import { Property, PropertyType, RecordType } from '@types';
import React, { useCallback, useMemo } from 'react';
import { ContextMenu } from '@kit/components/ContextMenu';
import moment from 'moment';
import { normalizeRoleName } from '@utils/roles';
import { useTeams } from '@hooks/useTeams';
import { useRoles } from '@hooks/useRoles';
import { useRoutes } from '@hooks/useRoutes';
import MenuItem from 'src/models/menu-item';
import { ArrowDown, ArrowUp, Edit2 } from 'react-feather';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { isDefaultPropertiesGroup, isNonePropertiesGroup, usePropertyGroupMutation } from '@hooks/usePropertyGroup';
import { useCompanyPropertiesMutations } from '@hooks/useCompanyProperties';
import { ChevronsUpIcon } from '@kit/ui/icons/ChevronsUp';
import { ChevronsDownIcon } from '@kit/ui/icons/ChevronsDown';
import { UserAvatar } from '@kit/components/UserAvatar';
import { capitalize } from 'lodash';
import { CopyButton } from '@common/ui';
import { PropertyForm } from './PropertyForm';
import { AccessNames, Copyable, PropertyTypeValue, User } from './styled';

const getPropertyTypeName = (property: Property) => {
  if (property.type === PropertyType.Dropdown) {
    return `${property.type} (${property.multiple === true ? 'multiple' : 'single'})`;
  }

  return property.type;
};

const renderPropertyDefaultValue = (property: Property) => {
  const value = property.additional?.defaultValue;

  if (!value) {
    return '-';
  }

  switch (property.type) {
    case 'PERSON':
      return (
        <User>
          <UserAvatar user={value} />
          <div>
            {value.firstName} {value.lastName}
          </div>
        </User>
      );
    case 'DATE':
      return moment(value).format('MM/DD/YYYY');

    default:
      return Array.isArray(value) ? value.join(', ') : value;
  }
};

const moveItem = (array: any[], from: number, to: number) => {
  const newArray = [...array];
  const item = newArray.splice(from, 1)[0];
  newArray.splice(to, 0, item);

  return newArray;
};

interface Props {
  property: Property;
  position: number;
  groups: { id: number; name: string; properties: Property[] }[];
  scope: RecordType;
  group: { id: number; name: string; properties: Property[] };
  isSearching: boolean;
  externalFormEnabled?: boolean;
}

export const PropertyRow = ({
  externalFormEnabled,
  isSearching,
  property,
  group: propertyGroup,
  scope,
  position,
  groups
}: Props) => {
  const { companyId } = useRoutes();
  const { openModal } = useModal();
  const confirmDelete = useConfirmDeleteModal();
  const {
    updatePropertyLayout: { mutateAsync: updatePropertyLayout }
  } = usePropertyGroupMutation();
  const {
    remove: { mutateAsync: remove }
  } = useCompanyPropertiesMutations();

  const { data: teams } = useTeams(companyId);
  const {
    rolesQuery: { data: roles }
  } = useRoles();

  // TODO Remove after addition roleName and teamName in property data, right now only id
  const getRoleName = (id: number | undefined) => {
    const role = roles?.find((item) => item.id === id);

    return normalizeRoleName(role?.name);
  };
  const getTeamName = (id: number | undefined) => {
    const team = teams?.find((item) => item.id === id);

    return team?.name;
  };

  const handlePropertyEdit = useCallback(() => {
    if (isDefaultPropertiesGroup(propertyGroup)) {
      return;
    }

    openModal<void>(
      ({ onClose }) => (
        <PropertyForm
          initialValuesGroup={propertyGroup}
          groups={groups}
          initialValues={property}
          scope={scope}
          onClose={onClose}
        />
      ),
      { title: 'Edit property' }
    );
  }, [openModal, property, propertyGroup, groups, scope]);

  const menuItems = useMemo<MenuItem[]>(() => {
    const isStandardGroup = isDefaultPropertiesGroup(propertyGroup);

    if (isStandardGroup) {
      return [];
    }

    return [
      !isSearching &&
        position !== 0 && {
          icon: <ArrowUp size="16px" color="#9C9CAA" />,
          title: 'Move up',
          onClick: async () => {
            await updatePropertyLayout({
              dto: groups
                .filter((group) => !isDefaultPropertiesGroup(group))
                .map((group) => ({
                  groupId: isNonePropertiesGroup(group) ? undefined : group.id,
                  scope,
                  propertyIds:
                    group.id === propertyGroup.id
                      ? moveItem(group.properties, position, position - 1).map((property) => property.id)
                      : group.properties.map((property) => property.id)
                }))
            });
          }
        },
      !isSearching &&
        position !== propertyGroup.properties.length - 1 && {
          icon: <ArrowDown size="16px" color="#9C9CAA" />,
          title: 'Move down',
          onClick: async () => {
            await updatePropertyLayout({
              dto: groups
                .filter((group) => !isDefaultPropertiesGroup(group))
                .map((group) => ({
                  groupId: isNonePropertiesGroup(group) ? undefined : group.id,
                  scope,
                  propertyIds:
                    group.id === propertyGroup.id
                      ? moveItem(group.properties, position, position + 1).map((property) => property.id)
                      : group.properties.map((property) => property.id)
                }))
            });
          }
        },
      !isSearching &&
        position !== 0 && {
          icon: <ChevronsUpIcon size="16px" color="#9C9CAA" />,
          title: 'Move to the top',
          onClick: async () => {
            await updatePropertyLayout({
              dto: groups
                .filter((group) => !isDefaultPropertiesGroup(group))
                .map((group) => ({
                  groupId: isNonePropertiesGroup(group) ? undefined : group.id,
                  scope,
                  propertyIds:
                    group.id === propertyGroup.id
                      ? moveItem(group.properties, position, 0).map((property) => property.id)
                      : group.properties.map((property) => property.id)
                }))
            });
          }
        },
      !isSearching &&
        position !== propertyGroup.properties.length - 1 && {
          icon: <ChevronsDownIcon size="16px" color="#9C9CAA" />,
          title: 'Move to the bottom',
          onClick: async () => {
            await updatePropertyLayout({
              dto: groups
                .filter((group) => !isDefaultPropertiesGroup(group))
                .map((group) => ({
                  groupId: isNonePropertiesGroup(group) ? undefined : group.id,
                  scope,
                  propertyIds:
                    group.id === propertyGroup.id
                      ? moveItem(group.properties, position, group.properties.length - 1).map((property) => property.id)
                      : group.properties.map((property) => property.id)
                }))
            });
          }
        },
      {
        icon: <Edit2 size="16px" color="#9C9CAA" />,
        title: 'Edit',
        onClick: () => {
          handlePropertyEdit();
        }
      },
      {
        icon: <TrashIcon size="16px" color="#D54855" />,
        title: 'Delete',
        onClick: async () => {
          if (await confirmDelete('Are you sure you want to delete this property?')) {
            await remove(property.id);
          }
        }
      }
    ].filter(Boolean);
  }, [
    isSearching,
    scope,
    remove,
    confirmDelete,
    groups,
    property,
    propertyGroup,
    position,
    updatePropertyLayout,
    handlePropertyEdit
  ]);

  const stopPropagation = useCallback<React.MouseEventHandler<HTMLTableCellElement>>((e) => {
    e.stopPropagation();
  }, []);

  return (
    <tr className={isDefaultPropertiesGroup(propertyGroup) ? undefined : 'clickable'} onClick={handlePropertyEdit}>
      <td width={externalFormEnabled ? '40%' : '45%'}>
        <div>{property.name}</div>
        <Copyable>
          {property.keyName || property.mappedName}
          <CopyButton value={property.keyName || property.mappedName} />
        </Copyable>
      </td>
      <td width="15%">
        <PropertyTypeValue>{getPropertyTypeName(property)}</PropertyTypeValue>
      </td>
      <td width="10%">{renderPropertyDefaultValue(property)}</td>
      <td width="5%">{capitalize(property.internalVisibility)}</td>
      {externalFormEnabled && <td width="5%">{capitalize(property.externalVisibility)}</td>}
      <td width="15%">
        <AccessNames>
          {(property.access?.length ?? 0) === 0 && 'All'}

          {property.access
            ?.map((access) => {
              if (access.type === 'team') {
                return `${getTeamName(access.teamId)} team`;
              }

              if (access.type === 'role') {
                return `${getRoleName(access.roleId)} role`;
              }

              return '';
            })
            .filter(Boolean)
            .join(', ')}
        </AccessNames>
      </td>
      <td width="10%" onClick={stopPropagation}>
        {menuItems.length > 0 && <ContextMenu items={menuItems} />}
      </td>
    </tr>
  );
};
